var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"columns is-justify-content-center"},[_c('div',{staticClass:"column is-half-desktop is-relative"},[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loadingProcesoActivo),callback:function ($$v) {_vm.loadingProcesoActivo=$$v},expression:"loadingProcesoActivo"}}),(!_vm.procesoActivo)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-field',{attrs:{"label":"Periodo","label-position":"on-border"}},[_c('b-datepicker',{attrs:{"required":"","type":"month","placeholder":"Seleccione un periodo","icon":"calendar-today","trap-focus":"","mobile-native":false},model:{value:(_vm.form.periodo),callback:function ($$v) {_vm.$set(_vm.form, "periodo", $$v)},expression:"form.periodo"}})],1),(
                                !_vm.tiposDocumentosLoading &&
                                _vm.tiposDocumentos.length === 0
                            )?_c('b-message',{attrs:{"type":"is-warning"}},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{attrs:{"icon":"alert-circle"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" No hay TIPOS DE DOCUMENTOS creados para su empresa. Haga click "),_c('router-link',{attrs:{"to":"/a/documentos/tipos-documentos"}},[_vm._v("aquí")]),_vm._v(" para crear uno. ")],1)],1)]):_vm._e(),_c('b-field',{staticClass:"mt-4",attrs:{"label":"Tipo de Documento","label-position":"on-border"}},[_c('b-select',{attrs:{"placeholder":"Selecciona una opción","loading":_vm.tiposDocumentosLoading,"disabled":!_vm.tiposDocumentosLoading &&
                                    _vm.tiposDocumentos.length === 0,"expanded":"","required":""},on:{"input":_vm.verification},model:{value:(_vm.form.tipo_documento_id),callback:function ($$v) {_vm.$set(_vm.form, "tipo_documento_id", $$v)},expression:"form.tipo_documento_id"}},_vm._l((_vm.tiposDocumentos),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.descripcion)+" ")])}),0)],1),(
                                !_vm.verifyAptoParaSubir &&
                                _vm.verifyAptoParaSubirMessage !== ''
                            )?_c('b-message',{attrs:{"type":"is-danger"}},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{attrs:{"icon":"alert-circle"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.verifyAptoParaSubirMessage)+" ")])],1)]):_vm._e(),_c('b-field',{staticClass:"mt-4",attrs:{"label":"Reportar resultados:","label-position":"on-border"}},[_c('b-input',{attrs:{"type":"email","placeholder":"Escriba dirección de correo electrónico","name":"email","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('br'),_c('b-field',[_c('b-checkbox',{model:{value:(_vm.form.enviar_email),callback:function ($$v) {_vm.$set(_vm.form, "enviar_email", $$v)},expression:"form.enviar_email"}},[_vm._v(" Avisar a los empleados de nuevo documento disponible via email. Los emails serán enviados en procesos automáticos.")])],1),_c('br'),_c('b-field',{staticClass:"file"},[_c('b-upload',{attrs:{"expanded":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('a',{staticClass:"button is-primary is-fullwidth"},[_c('b-icon',{attrs:{"icon":"upload"}}),(!_vm.file)?_c('span',[_vm._v(_vm._s('Subir en formato .zip'))]):_c('span',[_vm._v(_vm._s(_vm.file.name)+" ("+_vm._s(_vm.fileSize(_vm.file.size))+")")])],1)])],1),_c('div',{staticClass:"tags"},_vm._l((_vm.dropFiles),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_vm._v(" ("+_vm._s(_vm.fileSize(file.size))+") "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile(index)}}})])}),0),(
                                _vm.loading &&
                                !(
                                    _vm.upload_percent_completed == 0 ||
                                    _vm.upload_percent_completed == 100
                                )
                            )?_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"h4"},[_vm._v("Subiendo archivo")]),_c('b-progress',{attrs:{"type":"is-success","value":_vm.upload_percent_completed,"show-value":"","format":"percent"}})],1):_vm._e(),_c('b-button',{attrs:{"type":"is-success","disabled":!_vm.enableForm,"native-type":"submit","loading":_vm.loading,"expanded":""}},[_vm._v(" Cargar ")])],1)])]):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('b-notification',{attrs:{"type":"is-warning","closable":false,"has-icon":"","aria-close-label":"Close notification","role":"alert"}},[_vm._v(" Actualmente hay un proceso de "),_c('b',[_vm._v(_vm._s(_vm.procesoActivo.tipo.descripcion))]),_vm._v(" ejecutandose. Por favor, espere que termine que termine este proceso para ejecutar otro similar. ")]),_c('br'),_c('h5',[_vm._v("Información:")]),_c('ul',[_c('li',[_c('b',[_vm._v("Cargado por:")]),_vm._v(" "+_vm._s(_vm._f("nombreCompletoTrabajador")(_vm.procesoActivo.perfil.trabajador))+" ")]),_c('li',[_c('b',[_vm._v("Fecha y hora:")]),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.procesoActivo.created_at))+" ")])])],1)])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('h2',{staticClass:"has-text-primary-dark"},[_vm._v("Publicación Masiva")])])}]

export { render, staticRenderFns }