<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Publicación Masiva</h2>
        </div>
        <div class="columns is-justify-content-center">
            <div class="column is-half-desktop is-relative">
                <b-loading
                    v-model="loadingProcesoActivo"
                    :is-full-page="false"
                />
                <div class="card" v-if="!procesoActivo">
                    <div class="card-content">
                        <form @submit.prevent="handleSubmit">
                            <b-field label="Periodo" label-position="on-border">
                                <b-datepicker
                                    required
                                    type="month"
                                    v-model="form.periodo"
                                    placeholder="Seleccione un periodo"
                                    icon="calendar-today"
                                    trap-focus
                                    :mobile-native="false"
                                >
                                </b-datepicker>
                            </b-field>
                            <b-message
                                v-if="
                                    !tiposDocumentosLoading &&
                                    tiposDocumentos.length === 0
                                "
                                type="is-warning"
                            >
                                <div class="is-flex is-align-items-center">
                                    <b-icon icon="alert-circle" />
                                    <span class="ml-1">
                                        No hay TIPOS DE DOCUMENTOS creados para
                                        su empresa. Haga click
                                        <router-link
                                            to="/a/documentos/tipos-documentos"
                                            >aquí</router-link
                                        >
                                        para crear uno.
                                    </span>
                                </div>
                            </b-message>
                            <b-field
                                class="mt-4"
                                label="Tipo de Documento"
                                label-position="on-border"
                            >
                                <b-select
                                    placeholder="Selecciona una opción"
                                    v-model="form.tipo_documento_id"
                                    :loading="tiposDocumentosLoading"
                                    :disabled="
                                        !tiposDocumentosLoading &&
                                        tiposDocumentos.length === 0
                                    "
                                    @input="verification"
                                    expanded
                                    required
                                >
                                    <option
                                        v-for="option in tiposDocumentos"
                                        :value="option.id"
                                        :key="option.id"
                                    >
                                        {{ option.descripcion }}
                                    </option>
                                </b-select>
                            </b-field>
                            <b-message
                                v-if="
                                    !verifyAptoParaSubir &&
                                    verifyAptoParaSubirMessage !== ''
                                "
                                type="is-danger"
                            >
                                <div class="is-flex is-align-items-center">
                                    <b-icon icon="alert-circle" />
                                    <span class="ml-1">
                                        {{ verifyAptoParaSubirMessage }}
                                    </span>
                                </div>
                            </b-message>
                            <b-field
                                class="mt-4"
                                label="Reportar resultados:"
                                label-position="on-border"
                            >
                                <b-input
                                    type="email"
                                    placeholder="Escriba dirección de correo electrónico"
                                    v-model="form.email"
                                    name="email"
                                    required
                                />
                            </b-field>
                            <br />
                            <b-field>
                                <b-checkbox v-model="form.enviar_email">
                                    Avisar a los empleados de nuevo documento
                                    disponible via email. Los emails serán
                                    enviados en procesos
                                    automáticos.</b-checkbox
                                >
                            </b-field>
                            <br />
                            <b-field class="file">
                                <b-upload v-model="file" expanded>
                                    <a class="button is-primary is-fullwidth">
                                        <b-icon icon="upload"></b-icon>
                                        <span v-if="!file">{{
                                            'Subir en formato .zip'
                                        }}</span>
                                        <span v-else
                                            >{{ file.name }} ({{
                                                fileSize(file.size)
                                            }})</span
                                        >
                                    </a>
                                </b-upload>
                            </b-field>

                            <div class="tags">
                                <span
                                    v-for="(file, index) in dropFiles"
                                    :key="index"
                                    class="tag is-primary"
                                >
                                    ({{ fileSize(file.size) }}) {{ file.name }}
                                    <button
                                        class="delete is-small"
                                        type="button"
                                        @click="deleteDropFile(index)"
                                    ></button>
                                </span>
                            </div>

                            <div
                                v-if="
                                    loading &&
                                    !(
                                        upload_percent_completed == 0 ||
                                        upload_percent_completed == 100
                                    )
                                "
                                class="mb-3"
                            >
                                <p class="h4">Subiendo archivo</p>
                                <b-progress
                                    type="is-success"
                                    :value="upload_percent_completed"
                                    show-value
                                    format="percent"
                                >
                                </b-progress>
                            </div>

                            <b-button
                                type="is-success"
                                :disabled="!enableForm"
                                native-type="submit"
                                :loading="loading"
                                expanded
                            >
                                Cargar
                            </b-button>
                        </form>
                    </div>
                </div>
                <div class="card" v-else>
                    <div class="card-content">
                        <div class="content">
                            <b-notification
                                type="is-warning"
                                :closable="false"
                                has-icon
                                aria-close-label="Close notification"
                                role="alert"
                            >
                                Actualmente hay un proceso de
                                <b>{{ procesoActivo.tipo.descripcion }}</b>
                                ejecutandose. Por favor, espere que termine que
                                termine este proceso para ejecutar otro similar.
                            </b-notification>
                            <br />
                            <h5>Información:</h5>
                            <ul>
                                <li>
                                    <b>Cargado por:</b>
                                    {{
                                        procesoActivo.perfil.trabajador
                                            | nombreCompletoTrabajador
                                    }}
                                </li>
                                <li>
                                    <b>Fecha y hora:</b>
                                    {{ procesoActivo.created_at | moment }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Message from '@/utils/Message';

export default {
    data() {
        return {
            dropFiles: [],
            file: null,
            upload_percent_completed: 0,
            form: {
                periodo: undefined,
                tipo_documento_id: '',
                email: '',
                enviar_email: true,
            },
            loading: false,
            loadingProcesoActivo: false,
        };
    },
    async mounted() {
        await Promise.all([
            this.fetchTiposDocumentos(),
            this.fetchActiveProceso(),
        ]);
    },
    updated() {
        /* if (this.form.tipo_documento_id !== '') {
            this.verification();
        } */
    },
    computed: {
        procesoActivo() {
            return this.$store.state.procesos.active;
        },
        tiposDocumentos() {
            return this.$store.state.tiposDocumentos.data;
        },
        tiposDocumentosLoading() {
            return this.$store.state.tiposDocumentos.isLoading;
        },
        enableForm() {
            return (
                this.form.tipo_documento_id !== '' &&
                this.form.email !== '' &&
                this.form.periodo !== undefined &&
                this.file &&
                this.verifyAptoParaSubir
            );
        },
        verifyAptoParaSubir() {
            return this.$store.state.tiposDocumentos.verifyAptoParaSubirResponse
                .value;
        },
        verifyAptoParaSubirMessage() {
            return this.$store.state.tiposDocumentos.verifyAptoParaSubirResponse
                .message;
        },
    },
    methods: {
        deleteDropFile(index) {
            this.dropFiles.splice(index, 1);
        },
        fileSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        clearForm() {
            this.dropFiles = [];
            this.file = null;
            this.form.periodo = undefined;
            this.form.tipo_documento_id = '';
            this.form.email = '';
            this.form.enviar_email = true;
        },
        async fetchTiposDocumentos() {
            await this.$store.dispatch('tiposDocumentos/get', {
                sort: 'descripcion-ASC',
            });
        },
        async fetchActiveProceso() {
            try {
                this.loadingProcesoActivo = true;
                await this.$store.dispatch(
                    'procesos/getActive',
                    'carga-masiva-documentos'
                );
            } catch (err) {
                Message.showErrorMessage(
                    this,
                    'Error al obtener información del proceso'
                );
            } finally {
                this.loadingProcesoActivo = false;
            }
        },
        async handleSubmit() {
            this.loading = true;
            try {
                const { message } = await this.$store.dispatch(
                    'documentos/import',
                    {
                        file: this.file,
                        data: {
                            ...this.form,
                            periodo: moment(this.form.periodo)
                                .format('YYYY-MM-DD')
                                .toString(),
                            enviar_email: this.form.enviar_email ? 1 : 0,
                        },
                        onUploadProgress: (progressEvent) =>
                            this.onUploadProgress(progressEvent),
                    }
                );
                Message.showSuccessMessage(this, message);
                this.fetchActiveProceso();
                this.clearForm();
            } catch (err) {
                Message.showErrorMessage(this, err.message, err?.errors);
            } finally {
                this.loading = false;
            }
        },
        async verification() {
            try {
                await this.$store.dispatch(
                    'tiposDocumentos/verifyAptoParaSubir',
                    this.form.tipo_documento_id
                );
            } catch (err) {
                console.log(err);
            }
        },
        onUploadProgress(progressEvent) {
            const percent_completed = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(percent_completed);
            this.upload_percent_completed = percent_completed;
        },
    },
    filters: {
        nombreCompletoTrabajador: function ({
            nombre,
            apellido_paterno,
            apellido_materno,
        }) {
            return `${nombre} ${apellido_paterno} ${apellido_materno}`;
        },
        moment: function (date) {
            return moment(date).format('YYYY-MM-DD hh:mm:ss');
        },
    },
};
</script>

<style lang="scss" scoped>
.select select {
    height: 40px;
}
</style>
